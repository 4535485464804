import * as React from "react"
import styled from "styled-components"
import Layout from "../../../components/layout"
import Projects from "../../../components/pp_projects"
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../../../components/footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../../../components/wrap';
import Scheme from "../../../images/pp/pam_scheme.inline.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Premium Parking">
      <Header>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/head/pam.jpg"
          alt="Icons"
          height={460}
        />
        <Wrapper className="no-gaps">
          <div className="title">
            <h1>
              <small>Premium Parking</small>
              The transitioning of a usage-based revenue model to SaaS model with data-driven design approach</h1>
            <ul className="types">
              <li>
                <small>Timeframe</small>
                <br />
                Jan 2017–Jun 2018
              </li>
              <li>
                <small>Role</small>
                <br />
                Research, Product design, Prototyping
              </li>
            </ul>
          </div>
        </Wrapper>
      </Header>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <strong className="sub-title">Challenges and goals</strong>
        <h3>Satisfy own needs and expand to the market</h3>
        <p>At that time, Premium Parking used the IntegraPark (PARIS) solution for parking management and the web-app just for on-demand parking rates. The first epic for us was to build our own solution for parking management.</p>
        <p>
          The pain of using IntegraPark is a lack of flexibility for self-hosted versions. For MVP we decided to cover only our own needs, about 90 parking locations. We need to build an alternate solution for IntegraPark in 4 months. Later, we can use it to transform into a platform for parking owners (like SpotHero).
          <br />
          <AnchorLink to="#results">View Results</AnchorLink>
        </p>

        <strong className="sub-title">My role and the team</strong>
        <h3>Determining the right approach</h3>
        <p>
          As a member of the core team, I was the Product Designer during the project and worked alongside a Project Manager, Engineers, and CPO.
        </p>
        <p>
        I was responsible for defining the overall experience through mapping out the journey, design ideation, user validation, iteration, prototyping, and crafting high fidelity mockups throughout all stages of the project.
        </p>
        <p>
          I got buy-in from the CPO to run the project following a user-centered method. This way, we got to run user research throughout the design process, so we could understand our users, get feedback from them, and quickly iterate on our designs. Every week, I shared the status of the project with the whole team to make sure everyone was on the same page and key decisions could be taken together.
        </p>
      </Wrapper>
      <Wrapper>
        <strong className="sub-title">Design process</strong>
        <h3>Building a shared understanding</h3>
        <p>
          We ran user interviews with accounting and management, as they had a experience using of current solution.
        </p>
        <ul>
          <strong>Learnings from interviews - Round 1</strong>
          <li>Application stability (lack of development maintenance)</li>
          <li>Lack of flexibility, no way to enable specific feature on specific location</li>
          <li>Complexity of the terms, new people should be educated before using</li>
          <li>The solution was not responsive and design was extremely outdated</li>
          <li>There are should be several roles with own area</li>
          <li>List of necessary features for accounting</li>
        </ul>
        <strong>Hypothesis and ideation workshop</strong>

        <p>I invited my team and colleagues from development for a hypothesis creation and ideation workshop which helped us reach a common consensus through:</p>
        <ul>
          <li>Presenting the learnings from the first round of interviews</li>
          <li>Stating questions and assumptions</li>
          <li>Turning questions into hypotheses and further prioritization of these through grouping and dot voting</li>
          <li>Wireframing and white-boarding sessions</li>
        </ul>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
        <Images>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/pp_pam_v2.jpg"
            alt="Premium Account Manager 2.0"
          />
          <Scheme className="scheme" />
        </Images>
      </AnimationOnScroll>
      <Wrapper>
        <p>As a result, we understood all the roles, models, and their relationships. Additionally, we wrote down the modules development plan by stages.</p>

        <p>
          Based on the information we gathered in the sessions, I got to work on the most meaningful user flows for each role.
        </p>
        <h3>Designing the solution</h3>
        <p>I got to work on various wireframes both from a structural and an interaction perspective. </p>
      </Wrapper>
      <div className="screens-with-animations">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/pam_wireframes_groups.png"
            alt="Premium Account Manager Wireframes"
            className="img-with-theme-support"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/pam_wireframes_agreements.png"
            alt="Premium Account Manager Wireframes"
            className="img-with-theme-support"
          />
        </AnimationOnScroll>
      </div>
      <Wrapper>
        <p>
          At this stage, Premium Parking did not have any brand guidelines. I began to design layouts based on the existing appearance of the web-app, but more tidy and modern. Next, I designed high fidelity prototypes so that we could conduct Usability Testing (guerrilla usability testing).
        </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <div className="screens col-3">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/ba6.png"
            alt="Premium Account Manager Wireframes"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/cp3.png"
            alt="Premium Account Manager Wireframes"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/cp2.png"
            alt="Premium Account Manager Wireframes"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/ba3.png"
            alt="Premium Account Manager Wireframes"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/ba4.png"
            alt="Premium Account Manager Wireframes"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/ba5.png"
            alt="Premium Account Manager Wireframes"
          />
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <Wrapper>
        <strong className="sub-title">Stakeholder and User interviews - Round 2</strong>
        <h3>Validate the prototypes</h3>
        <p>Learnings - Round 2</p>
        <ul>
          Things that worked
          <li>Guiding the user. Users understood why they landed on this page and what is the task ahead</li>
          <li>The design was perceived as a solution that would get the user through the process faster than the current and as more modern, but recognizable as premium parking product</li>
          <li>No dead end routes</li>
        </ul>
        <ul>
          Things to improve
          <li>Not all terms was clear</li>
          <li>In case several roles applied, account switching between roles confuse</li>
        </ul>
        </Wrapper>
        <div className="wide-images">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/ba.png"
              alt="Premium Account Manager Wireframes"
              />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/ba2.png"
              alt="Premium Account Manager Wireframes"
            />
          </AnimationOnScroll>
        </div>
        <br/>
        <Wrapper>
        <h3>Reiterate and delivery</h3>
        <p>
          It was clear that communication with users would be playing a crucial role in creating a successful solution. Therefore, I collaborated more closely with the copy team and got better onboarding communication for users.
        </p>
        <p>Each feature or user flow was delivered to development as soon as it was ready.
        We have also finalized the launch plan including a plan for collecting metrics, getting tracking into place, and setting up screen recording through HotJar to observe any potential UX bugs. After launch we checked everything until the end of the billing cycle.</p>
        <h3>Launch and build at scale</h3>
        <p>
          Later, the sales department involved new customers and we accumulated feedback and analytics data.
          The collected data helped us develop new features during the year (Wallets, ACH, Promocodes, Permissions and more) or get rid of unused.
        </p>
      </Wrapper>
      
      <Wrapper id="results">
        <strong className="sub-title">Outcomes</strong>
        <h3>One year after the first release</h3>
        <ul>
          <li>
            The amount of markets increased by 4 times
          </li>
          <li>
            The amount of parking locations increased by 6 times
          </li>
          <li>
            MRR increased up to 10 times (in the long distance)
          </li>
        </ul>
        <h3>Year over year growth</h3>
        <p>
          Business Account was instrumental in getting Premium Parking to sign their enterprise clients (Oklahoma State Parks, Accor Hotels, Mariott, Fairmont, Hyatt Hotels, Sheraton, Mobile Cruise Terminal, Schlitterbahn waterpark) and is having a measurable impact in allowing the company to expand to additional markets. During the next 5 years, I still got involved in designing new features, but in the background with other premium products. At the beginning, Business Account from Premium Parking was in Top 10 and later Top 5 alternatives of IntegraPark. Later it becomes industry leader in the US. 
        </p>

      </Wrapper>
      <Projects project="pam" />
      </AnimationOnScroll>
      <Footer />
    </Layout>
  )
}

const Images = styled.div`
  margin-bottom: 3rem;
  position: relative;
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    border-radius: 8px;
    box-shadow: 3px 25px 40px -15px rgb(0 0 0 / 20%);
    max-width: 50%;
    height: autp;
    margin: 0 10px;
  }

  .scheme {
    background: #fff;
    box-shadow: 3px 25px 40px -15px rgb(0 0 0 / 20%);
    max-width: 55%;
    height: auto;
    margin-left: -5%;
    position: relative;
    border-radius: 8px;
    margin-right: 10px;
  }

  @media (max-width: 990px) {
    display: block;
    
    .scheme,
    .gatsby-image-wrapper {
      height: auto;
      max-width: 100%;
      margin: 0 10px;
    }
  }

  @media (max-width: 990px) {
    .gatsby-image-wrapper {
      min-height: 150px;
    }
  }
`

const Header = styled.div`
  background: #2f3238;
  text-align: right;
  color: #fff;
  margin-bottom: 50px;
  overflow: hidden;

  .title {
    text-align: left;
    max-width: 800px;
  }

  .no-gaps {
    padding-bottom: 0;
  }

  p {
    font-size: 1.3rem;
    margin: 0;
    line-height: 1.3;
  }

  @media (max-width: 1350px) {
    .title {
      max-width: 500px;
      bottom: 20px;
    }

    p {
      margin: 5px 0 0;
      line-height: 1.5;
    }
    
    h1 {
      margin-bottom: 5px;
      font-size: var(--fontSize-5);
    }

    .gatsby-image-wrapper {
      margin-right: -50px;
    }
  }

  @media (max-width: 1280px) {
    .gatsby-image-wrapper {
      margin-right: -270px;
    }

    h1 {
      font-size: var(--fontSize-4);
    }
  }

  @media (max-width: 768px) {
    .gatsby-image-wrapper {
      margin-top: -50px;
    }
    
    small,
    .types {
      font-size: var(--fontSize-2);
    }
  }

  @media (min-width: 675px) {
    .title {
      position: absolute;
      left: 20px;
      bottom: 30px;
    }
  }

  @media (max-width: 675px) {
    padding-bottom: 30px;
    
    .title {
      max-width: 100%;
      padding-bottom: 20px;
      padding-top: 10px;
    }

    .gatsby-image-wrapper {
      margin-right: 0;
      margin-top: 0;
    }

    h1 {
      font-size: var(--fontSize-4);
    }
  }
`
export default IndexPage
